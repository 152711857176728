
export default {
  name: 'SpacerBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    symmetrical() {
      return !!this.blok.symmetrical
    },
    vPadding() {
      const value = this.blok.vertical ? this.blok.vertical : 1
      const prefix = this.symmetrical ? 'y' : 't'
      if (value < 0) {
        return `m${prefix}-n${-value}`
      }
      return `p${prefix}-${value}`
    },
    hPadding() {
      const value = this.blok.horizontal ? this.blok.horizontal : 0
      const prefix = this.symmetrical ? 'x' : 'r'
      if (value < 0) {
        return `m${prefix}-n${-value}`
      }
      return `p${prefix}-${value}`
    },
    style() {
      if (this.blok.maxWidth) {
        return {
          maxWidth: this.blok.maxWidth + 'px',
          margin: '0 auto',
        }
      }
      return ""
    }
  },
}
